import './assets/css/styles.scss';
import Home from './pages/Home';
import ScrollToTop from './ScrollToTop';
import Navbar from './pages/common/Navbar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './pages/common/Footer';
import Privacypolicy from './pages/Privacypolicy';
import TermsofUse from './pages/TermsofUse';
import Cookiepolicy from './pages/Cookiepolicy';
import Attributions from './pages/Attributions';
import About from './pages/About';
import Error from './pages/Error';
import ContactUs from './pages/ContactUs';

function App() {



  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/privacy-policy" element={<Privacypolicy />} />
          <Route exact path="/terms" element={<TermsofUse />} />
          <Route exact path="/cookie-policy" element={<Cookiepolicy />} />
          <Route exact path="/attributions" element={<Attributions />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/about" element={<About />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
