import React from 'react';
import { Container, Typography, Box, Divider, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif',
    },
});

function Contact() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

                <Box mt={3}>
                    <Typography paragraph>
                        At NV Wallet, we are committed to providing exceptional service and support to our users. Whether you require assistance with using our wallet, are encountering an issue, or wish to learn more about our offerings, we are here to assist you every step of the way.                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h4" component="h2" mb={3}>
                        Contact Us
                    </Typography>
                    <Typography paragraph>
                        For any inquiries or support needs, please do not hesitate to reach out to our dedicated support team. We are available to offer prompt, dependable, and secure assistance to ensure that your experience with NV Wallet remains seamless and enjoyable.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" component="h2" mb={1}>
                        Email:
                    </Typography>
                    <Typography paragraph>
                        For general inquiries, feedback, and support requests, please contact us at
                        <a style={{ marginLeft: "10px", textDecoration: "underline" }} href="mailto:It@aegeaholdings.com">It@aegeaholdings.com</a>
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" component="h2" mb={1}>
                        Support Channels
                    </Typography>
                    <Typography paragraph>
                        We take your security and satisfaction seriously and offer multiple support channels to address a variety of requests. Each inquiry is handled with the utmost priority, whether you require technical assistance, have questions about your account, or need information about our services.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" component="h2" mb={1}>
                        Technical Support:
                    </Typography>
                    <Typography paragraph>
                        Should you experience any technical difficulties, such as issues with transactions or accessing your account, please contact our technical support team at
                        <a style={{ marginLeft: "10px", textDecoration: "underline" }} href="mailto:It@aegeaholdings.com">It@aegeaholdings.com</a>
                    </Typography>
                    <Typography paragraph>
                        We aim to resolve your issues swiftly, with a dedicated team ready to provide assistance.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" component="h2" mb={1}>
                        Account Assistance
                    </Typography>
                    <Typography paragraph>
                        For inquiries related to your account, including security concerns, transaction issues, or verification, our account assistance specialists are available to support you.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" component="h2" mb={1}>
                        Feedback and Suggestions
                    </Typography>
                    <Typography paragraph>
                        We welcome and appreciate your feedback. If you have suggestions or ideas on how we can improve Kerdos, please do not hesitate to share them by emailing us at
                        <a style={{ marginLeft: "10px", textDecoration: "underline" }} href="mailto:It@aegeaholdings.com">It@aegeaholdings.com</a>
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" component="h2" mb={1}>
                        Our Commitment to You
                    </Typography>
                    <Typography paragraph>
                        Your trust is fundamental to our mission, and we prioritize your satisfaction in all interactions. We ensure that every communication with us is secure and confidential, with a team dedicated to safeguarding the privacy and integrity of your information. We strive to foster a relationship with our users built on reliability, security, and transparency.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h6" component="h2" mb={1}>
                    Thank you for choosing NV Wallet. We look forward to serving you and supporting your needs.
                    </Typography>
                </Box>

            </Container>
        </ThemeProvider>
    );
}

export default Contact;
