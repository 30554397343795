import React from 'react'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
// import LogoWhite from "../../assets/images/BitLogotext.png";
import LogoWhite from "../../assets/images/nvwallet_14x.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AppstoreIcon from '../../assets/images/AppstoreIcon.svg';
import PlaystoreIcon from '../../assets/images/PlaystoreIcon.svg';
import { FaInstagram, FaDiscord, FaTelegramPlane, FaYoutube, FaTiktok } from 'react-icons/fa';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


export default function Footer() {

    const notify = () => toast("Coming soon");


    return (
        <section className='top-home-footer-section'>
            <ToastContainer />
            <Container maxWidth="lg" className='top-home-footer-section-1'>
                <Grid container
                    spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Link to="/" className='footer-brand'>
                            <LazyLoadImage
                                src={LogoWhite}
                                alt='store'
                                style={{ width: '70%', height: 'auto', display: 'block', marginBottom: "20px" }}
                            />
                        </Link>
                        <div className="social-menu-new-footer mt-5">
                            <ul className='p-0 d-flex flex-row'>
                                <li><Link to="https://www.facebook.com/" target="blank" rel="noopener noreferrer"><FaFacebookF className='fab' /></Link></li>
                                <li><Link to="https://x.com/i/flow/login?redirect_after_login=%2Fbitnevex" target="blank" rel="noopener noreferrer"><FaXTwitter className='fab' /></Link></li>
                                <li><Link to="https://t.me/bitnevex" target="blank" rel="noopener noreferrer"><FaTelegramPlane className='fab' /></Link></li>
                                <li><Link to="https://www.youtube.com/@bitnevex" target="blank" rel="noopener noreferrer"><FaYoutube className='fab' /></Link></li>
                            </ul>
                            <ul className='p-0'>
                                <li><Link to="https://www.instagram.com/bitnevex/?utm_source=qr&igsh=MW8xYjZvazlxOTF0YQ%3D%3D" target="blank" rel="noopener noreferrer"><FaInstagram className='fab' /></Link></li>
                                <li><Link to="https://discord.com/invite/GSkPd2Ve" target="blank" rel="noopener noreferrer"><FaDiscord className='fab' /></Link></li>
                                <li><Link to="https://www.tiktok.com/@bitn3v3x?_t=8sNAN9uLUsJ&amp;_r=1" target="blank" rel="noopener noreferrer"><FaTiktok className='fab' /></Link></li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={7} sm={7} md={2} lg={3}>
                        <Stack
                            direction="column"
                            useFlexGap
                            sx={{ flexWrap: 'wrap' }}
                        >
                            <Link to="/about">About</Link>
                            <Link to="/attributions">Attributions</Link>
                            <Link to="/cookie-policy">Cookies</Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={7} sm={7} md={2} lg={3}>
                        <Stack
                            direction="column"
                            useFlexGap
                            sx={{ flexWrap: 'wrap' }}
                        >
                            <Link to="/privacy-policy">Privacy Policy</Link>
                            <Link to="/terms">Terms of Use</Link>
                            <Link to="/contact-us">Contact Us</Link>
                            {/* <Link to="">LicenseAggrement</Link> */}
                        </Stack>
                    </Grid>

                    <Grid item xs={7} sm={7} md={4} lg={3}>
                        <Link onClick={notify}>
                            <LazyLoadImage
                                src={AppstoreIcon}
                                alt='Appstore'
                                style={{ width: '70%', height: 'auto', display: 'block', marginBottom: "20px" }}
                            />
                        </Link>

                        <Link to="https://play.google.com/store/apps/details?id=com.nvwallet.wallet" target='_blank'>
                            <LazyLoadImage
                                src={PlaystoreIcon}
                                alt='Playstore'
                                style={{ width: '70%', height: 'auto', display: 'block' }}
                            />
                        </Link>

                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}
